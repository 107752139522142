<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>会议新闻</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">

      <el-row :gutter="20">
        <el-col :span="18">
          <div class="left-box">
            <el-carousel indicator-position="outside" height="450px">
              <el-carousel-item v-for="(item, i) in imglist" :key="'img' + i">
                <img class="carousel-img" :src="item.url" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>

        </el-col>
        <el-col :span="6">

          <div>
            <!-- 会议 -->
            <div class="box xshy">
              <div class="t clearfix bbd pr10">
                <div class="tit fl">重要会议</div>
                <!-- <a class="more" @click="goTZlist">更多&gt;&gt;</a> -->
              </div>
              <div>
                <el-card class="custom-card" v-for="(item, i) in hytypes" :key="'hycard' + i">
                  <img :src="cardImgs[i].url" class="card-image" @click="onClickCard(item)">
                </el-card>
              </div>
            </div>

          </div>
        </el-col>
      </el-row>

      <!-- 通知 -->
      <div class=" box xshy">
        <div class="t clearfix bbd pr10">
          <div class="tit fl">重要通知</div><a class="more" @click="goTZlist">更多&gt;&gt;</a>
        </div>
        <div class="c">
          <ul class="dotlist lh18">
            <li class="ellipsis cus_link">
              <el-link :underline="false" @click="onClick('4')">国家卫生健康委办公厅关于印发急诊医学等6个专业医疗质量控制指标（2024年版）的通知
                “2024-05-29”
              </el-link>
            </li>
            <li class="ellipsis cus_link">
              <el-link :underline="false" @click="onClick('2')">关于召开2024年山东省医学影像学质量控制中心工作会议的通知
                “2024-01-26”</el-link>
            </li>
          </ul>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "HYXW",
  data() {
    return {
      hytypes: [
        {
          type: 1,
          name: "国家级放射影像专业质控中心工作会议",
        },
        {
          type: 2,
          name: "国家级放射影像专业质控中心亚专业组工作会议",
        },
        {
          type: 4,
          name: "省级放射影像专业质控中心工作会议",
        },
        {
          type: 3,
          name: "地市级放射影像专业质控中心工作会议",
        },
      ],
      imglist: [
        {
          url: require('@/assets/GJJ2024/1.jpg')
        },
        {
          url: require('@/assets/GJJ2024/2.jpg')
        },
      ],
      cardImgs: [
        {
          url: require('@/assets/zyhy/1.jpg')
        },
        {
          url: require('@/assets/zyhy/2.jpg')
        },
        {
          url: require('@/assets/zyhy/3.jpg')
        },
        {
          url: require('@/assets/zyhy/4.jpg')
        },
      ],
    }
  },
  methods: {
    onClick(seq) {
      if (seq == '1') {
        this.$router.push('/XSJX')
      } else if (seq == '2') {
        this.$router.push('/XW2')
      } else if (seq == '3') {
        this.$router.push('/SDSHYJB')
      } else if (seq == '5') {
        this.$router.push('/GZSPX')
      } else if (seq == '7') {
        this.$router.push('/HLJZJH')
      } else if (seq == '9') {
        this.$router.push('/GJJ2024')
      } else if (seq == '10') {
        this.$router.push('/JLS2024')
      } else if (seq == '11') {
        this.$router.push('/SZ2024')
      } else if (seq == '13') {
        this.$router.push('/GDS2024')
      } else if (seq == '4') {
        this.$router.push('/YLZLKZBZ2024')
      } else if (seq == '15') {
        this.$router.push('/HY20240622')
      } else if (seq == '17') {
        this.$router.push('/HY20240623')
      } else if (seq == '19') {
        this.$router.push('/HY20240701')
      } else if (seq == '21') {
        this.$router.push('/HY20240712')
      } else if (seq == '23') {
        this.$router.push('/HY20240723')
      } else if (seq == '25') {
        this.$router.push('/HY20240724')
      } else if (seq == '27') {
        this.$router.push('/HY20240812')
      } else if (seq == '29') {
        this.$router.push('/HY20240815')
      } else if (seq == '31') {
        this.$router.push('/HY20240827')
      } else if (seq == '33') {
        this.$router.push('/HY20240828')
      } else if (seq == '35') {
        this.$router.push('/HY20241018')
      } else if (seq == '37') {
        this.$router.push('/HY20241021')
      }
    },
    onClickCard(item) {
      sessionStorage.HY_TYPE = item.type;
      this.$router.push(`/HY_list`)
    },
    goHYlist() {
      this.$router.push('/HY_list')
    },
    goTZlist() {
      this.$router.push('/TZ_list')
    },
  }
}
</script>

<style scoped>
.wrap {
  width: 1200px;
  margin: 40px auto;
}

.box {
  min-height: 280px;
  background-color: #fff;
  margin-bottom: 20px;
}

.box .t {
  line-height: 40px;
  min-height: 40px;
  height: auto !important;
  height: 40px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
}

.bbd {
  border-bottom: 1px solid #eee;
}

.box .t .tit {
  height: 16px;
  line-height: 16px;
  color: #2b4a8f;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  /* background: url(../images/boxt.png) left center no-repeat; */
  margin-top: 12px;
}

a.more,
a.more:link,
a.more:visited {
  font-weight: normal;
  float: right;
  color: #777;
}

.pr10 {
  padding-right: 10px;
}

.lh18 {
  line-height: 1.8;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

ul,
li {
  list-style-type: none;
}

.dotlist li {
  /* background: url(../images/dot.png) left center no-repeat; */
  padding: 5px 0px;
  padding-left: 10px;
}

.ellipsis {}

.el-link::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #777;
  margin-right: 8px;
  vertical-align: middle;
}


a,
a:link {
  color: #777;
  transition: all 0.3s;
}

a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.cus_link {
  margin-top: 20px;
}

.cus_link>>>.el-link {
  font-size: 16px;
}

.cus_link>>>.el-link {
  /* text-overflow: ellipsis; */
  white-space: normal;
  overflow: hidden;
  display: block;
}

.left-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-carousel {
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  /* background-color: #99a9bf; */
}

.el-carousel__item:nth-child(2n+1) {
  /* background-color: #d3dce6; */
}

.custom-card {
  display: inline-block;
  width: 100%;
  /* 初始宽度 */
  transition: width 0.3s ease, box-shadow 0.3s ease;
  /* 添加过渡效果 */
  padding: 4px;
  box-sizing: border-box;
}

.custom-card>>>.el-card__body {
  padding: 0;
}

.custom-card:hover {
  width: 120%;
  /* 悬停时宽度增加50px */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  /* 加深阴影 */
  /* background-color: #b3c7e3; */
}


.carousel-img {
  width: 100%;
}

.card-image {
  width: 100%;
}
</style>